export const INTEGRATED_CHECK_TYPES = ["non reference", "integrated", "neotas integrated check", "integrated check"];

export const TOOLS_LICENSE_MAP = {
  "lexis-bridger-tool": ["lexis_bridger__adverse_pep_sacntion"],
  "company-name": ["bvd__corp_record", "db__corp_record"],
  "search-engine-tool": ["search_engine__search_engine_"]
};

export const CHECK_TOOLS = [ "lexis-bridger-tool", "company-name", "search-engine-tool" ];

export const TOOL_TO_CHECK_NAME_MAP = {
  "company-name": ["corporate records", "poe"],
  "lexis-bridger-tool": ["lexis bridger", "lexis-bridger", "kyc"],
  "search-engine-tool": ["search engine"],
};
