import mainAxios from "@shared/light-ray/axios";
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { TOOL_TO_CHECK_NAME_MAP } from "../utils/constants"
export default {
  computed: {
    ...mapGetters([
      "getToolsData",
      "getAnalysisToolData",
      "getNeoCaseId",
      "searchedQueries",
      "monitoringTools",
      "queryMonitoringEvents",
      "getProduct"
    ]),
    fetchSearchedQueries() {
      let queries = cloneDeep(this.searchedQueries).map((query) => ({
        ...query,
        total_results:this.queriesResultsCount?.find((mq) => mq?.query_id === query._id)?.result_count || 0,
        events: this.queryMonitoringEvents ? this.queryMonitoringEvents[query._id] : null,
        monitoring_id: this.monitoringQueries.find((mq) => mq?.query.query_id === query._id)?._id
      })); // merge searchedQueries and monitoring queries with same id
      queries = queries.map((query) => {
        if (
          query.tool === "lexis-bridger-tool" &&
          query.query.entity_type === "Individual"
        ) {
          if (!query.query.person_entity.full_name) {
            let list = [];
            list.push(query.query.person_entity.first_name);
            list.push(query.query.person_entity.middle_name);
            list.push(query.query.person_entity.last_name);
            query.query.person_entity.full_name = list
              .filter((el) => el)
              .join(" ");
          }
          return query;
        } else return query;
      });
      return queries?.filter((n) => n.tool == this.$route.query.check_name);
    },
  },
  methods: {
    ...mapActions([
      "getAnalysisDropdownData",
      "fetchCasePersonalData",
      "getToolsStatus",
      "getDataMonitoring",
      "setQueryResultLoader"
    ]),
    async handleMonitoringStatus(event) {
      // const index = this.fetchSearchedQueries.findIndex((n) => n._id == event._id);
      // if (index != -1) {
      //     this.fetchSearchedQueries[index].monitoring_status = event.monitoring_status;
      //     this.$forceUpdate();
      // }
      await mainAxios.put("/service/tools-monitoring", {
          case_id: this.getNeoCaseId,
          id: event._id,
          monitoring_status: event.is_monitoring,
          tool: this.$route.query.check_name,
      });
      await this.getToolsStatus();
      const query = {
          ...event,
          ...{
              _id: event._id,
              monitoring_id: event.monitoring_id,
              tool: this.$route.query.check_name,
          },
      };
      this.updateFrequency({
          query: query,
          isQuery: true,
          maskedQuery: this.viewQuery(query),
      });
      // let inherit_settings = this.monitoringTools[this.$route.query.check_name] && this.monitoringTools[this.$route.query.check_name].status === "enabled" && !this.monitoringTools[this.$route.query.check_name].has_inner_level_monitoring ? this.monitoringTools[this.$route.query.check_name] : this.productMonitoring.find((product) => product.tool ===  this.$route.query.check_name);
      this.updateMonitoring({
          frequency: 30,
          monitoring_status: event.is_monitoring ? "enabled" : "disabled",
      });
      
    },
    updateFrequency(data) {
      if (data?.isQuery) {
          this.activeMonitoring = {
              ...data?.query,
              monitoring_type: "query",
              status: data?.query.monitoring_status ? "enabled" : "disabled",
              tool: data?.query.tool,
          };
          this.activeMonitoring.query = {
              query_id: data?.query._id,
              query_string: data?.maskedQuery,
              search_string: data?.maskedQuery,
          };
      } else {
          this.activeMonitoring = {
              ...data?.query,
              monitoring_type: "case",
          };
      }
    },
    updateMonitoring(data) {

      this.activeMonitoring = {
          ...this.activeMonitoring,
          ...{
              status: data.monitoring_status,
              frequency: data.frequency,
              count: data.count,
              duration: data.duration,
          },
      };
      this.updateActiveMonitoring();
    },
    updateActiveMonitoring() {
      let match = false;
      
      if (this.activeMonitoring.monitoring_type === "query") {
          if (this.query_ids.includes(this.activeMonitoring?.query?.query_id)) match = true;
      } else {
          if (this.tools.includes(this.activeMonitoring.tool) && this.activeMonitoring._id) match = true;
      }
    
      if (match) this.updateDataMonitoring(this.activeMonitoring);
      else this.createDataMonitoring(this.activeMonitoring);
    },
    async getQueriesMonitoring() {
          const tool_name = this.$route.query.check_name || this.autorun?.headers['x-tool-name']
      
          let url = `/service/data-monitoring/query?case_id=${this.getNeoCaseId}&tool=${tool_name}`;
          // let data = {
          //     monitoring_type: "query",
          //     case_id: this.$route.params?.id,
          //     tool: this.currentToolSelected,
          // };
          try {
              // let response = await axios.post(url, data);
              let response = await mainAxios.post(url);
              if (response?.status === 200 && response?.data?.data) {
                  this.monitoringQueries = response?.data?.data;
                  this.query_ids = this.monitoringQueries.map((mq) => mq?.query?.query_id); // map an array for query_ids with monitoring on
              }
          } catch (error) {
              console.error(error);
          }
      
    },
    async createDataMonitoring(monitoring) {
      let url = `/service/data-monitoring`;
      let freq = parseInt(monitoring.frequency, 10);
      let data = {
          case_id: this.getNeoCaseId,
          tool: monitoring.activeProcess || monitoring.tool,
          frequency: freq,
          monitoring_type: monitoring.monitoring_type || "case",
          query: monitoring.query,
          product_name: this.getProduct?.name,
          product_id: this.getProduct?.key,
          status: monitoring.status,
          count: parseInt(monitoring.count, 10),
          duration: parseInt(monitoring.duration, 10),
      };
      try {
          let response = await mainAxios.post(url, data);
          if ((response?.status === 201 || response?.status === 200) && response?.data.status) {
              this.$toast.success("Monitoring set successfully !");

              if (this.activeMonitoring.monitoring_type === "query") {
                  await this.getQueriesMonitoring(); // Function to fetch monitoring data
                  await this.getDataMonitoring(); // Function to fetch monitoring data
              } else {
                  await this.getDataMonitoring(); // Function to fetch monitoring data
              }
          }
      } catch (error) {
          console.error(error);
      }
    },

  // Function to update monitoring data
    async updateDataMonitoring(monitoring) {
      
      let url = `/service/data-monitoring`;
      let freq = parseInt(monitoring.frequency, 10);
      let data = {
          id: monitoring.monitoring_type === "query" ? monitoring.monitoring_id : monitoring._id,
          status: monitoring.status,
          frequency: freq,
          count: parseInt(monitoring.count, 10),
          duration: parseInt(monitoring.duration, 10),
          monitoring_type: monitoring.monitoring_type || "case",
          query: monitoring.query,
          case_id: this.getNeoCaseId
      };
      try {
          let response = await mainAxios.put(url, data);
          if (response?.status === 200 && response?.data.status) {
          
              this.$toast.success("Monitoring updated successfully !");
              if (this.activeMonitoring.monitoring_type === "query") {
                  await this.getQueriesMonitoring(); // Function to fetch monitoring data
                  await this.getDataMonitoring(); // Function to fetch monitoring data
              } else {
                  await this.getDataMonitoring(); // Function to fetch monitoring data
              }
              // this.close();
            
          }
      } catch (error) {
          console.error(error);
      }
    },
    viewQuery({query}) {
      let name = Object.keys(query).filter((n) => n == "name");
      if (name) {
          query = {name: query["name"], ...query};
      }
      return Object.entries(query)
          .map((n) => (n[0] != "page_size" && n[0] != "start" ? n[1] : ""))
          .filter((n) => n)
          .join(", ");
    },
    getIntegratedToolName(checkName) {
      const check = checkName?.toLowerCase()
      let toolName = ''

      for (const key in TOOL_TO_CHECK_NAME_MAP) {
        for (const checkName of TOOL_TO_CHECK_NAME_MAP[key]) {
          if (check.includes(checkName)) {
            toolName = key;
            break;
          }
        }
      }

      return toolName;
    },
    async getQueriesResultsCount(toolName) {
        let url = `/service/query/results-count`;
            let data = {
                case_id: this.getNeoCaseId,
                tool: toolName,
            };
            try {
                // let response = await axios.post(url, data);
                let response = await mainAxios.post(url, data);
                if (response?.status === 200 && response?.data?.data) {
                    this.queriesResultsCount = response?.data?.data;
                }
            } catch (error) {
                console.error(error);
            }
    },
    async handleRefreshQueries() {
      const { search_queries } = this.$refs;
      await search_queries.handleRefreshQueries();
    },
    async getToolsSavedData() {
      await this.getAnalysisDropdownData({
        url: `service/${this.getNeoCaseId}/tools/profiles`,
      });
      this.toolData = this.getAnalysisToolData;
    },
    async handleSearchedQueries() {
      try {
        let body = { case_id: this.getNeoCaseId };
        const { data: toolsStatus } = await mainAxios.post(
          `service/tools-status`,
          body
        );
        return toolsStatus.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearchQueryClicked(query) {
      try {
      this.setQueryResultLoader(true);
      this.checkSearched = false;
      const { tools } = this.$refs;
      let query_id = "";
      let sel_query = null;
      if (query._id) {
        query_id = query._id;
        sel_query = this.fetchSearchedQueries?.find((el) => el._id === query_id)
          ?.query;
      }
      tools.request_id = query_id;
      tools.monitoringActive = query.is_monitoring
      switch (this.currentCheckName) {
        case "company-name": {
          tools.setDefaultFilters()
          tools.showFiltersPanel = false
          const sortedQuery = Object.keys(sel_query)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, key) => {
              acc[key] = sel_query[key];
              return acc;
            }, {});
          let keys = Object.keys(sortedQuery);
          if (keys.includes("address_locality")) {
            keys.shift();
          }
          if (keys.includes("address_region")) {
            keys.shift();
          }
          if (keys.includes("code")) {
            keys.shift();
          }
          if (keys.includes("duns")) {
            keys.shift();
          }
          if (keys.includes("email")) {
            keys.shift();
          }
          if (keys.includes("mode")) {
            keys.shift();
          }
          if (sel_query?.name && sel_query?.name!='') {
            keys[0] = 'name'
          }
          if (sel_query?.officer && sel_query?.officer!='') {
            keys[0] = 'officer'
          } 
          tools.showSearch = true;
          tools.showResult = false;
          tools.request_id = query_id;

          if (sel_query.sources) {
            tools.openCorporatesCheck = sel_query.sources.includes(
              "openCorporates"
            );
            tools.clarifiedByCheck = sel_query.sources.includes(
              "clarifiedBy"
            );
            tools.companyWatchCheck = sel_query.sources.includes(
              "companyWatch"
            );
            tools.bvdCheck = sel_query.sources.includes("bvd");
            tools.dnbCheck = sel_query.sources.includes("db");
          }
          
          switch (keys[0]) {
            case "company": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.officer = "";
              tools.address = "";
              tools.searchKeyword = sel_query?.company ?? "";
              tools.selectVal = sel_query?.company ? [sel_query?.company] : [];
              tools.address = sel_query?.street_address_line1 || "";
              tools.postCode = sel_query?.postal_code || "";
              tools.locality = sel_query?.address_locality || "";
              tools.region = sel_query?.address_region || "";
              tools.registrationNumber = sel_query?.registration_number || "";
              tools.phone = sel_query?.telephone_number || "";
              tools.duns = sel_query?.duns || "";
              tools.email = sel_query?.email || "";
              let jurisdictionComp = null;
              if (sel_query.code) {
                jurisdictionComp = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionComp ?? null;
              if (sel_query.mode) {
                let search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }
              tools.selectedItem = {
                value: sel_query?.company ?? "",
              };
              tools.fieldType = {
                text: "Company",
                value: "company",
              };
              break;
            }
            case "name": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.officer = "";
              tools.address = "";
              tools.jurisdiction = "";
              tools.searchKeyword = sel_query?.name ?? "";
              tools.selectVal = sel_query?.name ? [sel_query?.name] : [""];
              tools.clearFilters();
              if (sel_query?.country) {
                let selectedCountry = tools.jurisdictionList?.[0]?.categories.find((el) => el.value === sel_query.country);
                tools.selectedJurisdictionFilter = selectedCountry
              //   let allCountryList = this.$store.getters.getAutoPopulatedCountryList;
              //   let selectedCountry = allCountryList?.find((el) => el.name === sel_query.city);
              //   tools.selectedJurisdictionFilter = {
              //     label: selectedCountry?.name,
              //     value: selectedCountry?.code,
              // }
              } else {
                tools.selectedJurisdictionFilter= null;
              }
              let jurisdictionName = null;
              let dbJurisdictionName = null;
              if (
                sel_query.code &&
                sel_query.sources.includes("opencorporates")
              ) {
                jurisdictionName = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionName ?? null;
              if (sel_query.code && sel_query.sources.includes("db")) {
                dbJurisdictionName = tools.dbJurisdictionList[0].categories.find(
                  (n) => n.country_code.toLowerCase() === sel_query.code
                );
              }
              tools.dbJurisdiction = dbJurisdictionName ?? null;
              tools.dbaddress = sel_query?.street_address_line1 ?? "";
              tools.postCode = sel_query?.postal_code ?? "";
              tools.locality = sel_query?.address_locality ?? "";
              tools.region = sel_query?.address_region ?? "";
              tools.registrationNumber = sel_query?.registration_number ?? "";
              tools.phone = sel_query?.telephone_number ?? "";
              tools.duns = sel_query?.duns ?? "";
              tools.email = sel_query?.email ?? "";
              let search_mode_find = null;
              if (
                sel_query?.street_address_line1 ||
                sel_query?.postal_code ||
                sel_query?.address_locality ||
                sel_query?.address_region ||
                sel_query?.registration_number ||
                sel_query?.telephone_number ||
                sel_query?.duns ||
                sel_query?.email
              ) {
                tools.isAdvancedSearch = true;
              }
              if (sel_query.mode) {
                search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }
              tools.selectedItem = {
                value: sel_query?.name ?? "",
              };
              tools.fieldType = {
                text: "Company",
                value: "company",
              };
              break;
            }
            case "officer": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.searchKeyword = "";
              tools.address = "";
              tools.jurisdiction = "";
              tools.selectedCountryFilter= null;
              tools.BVDDateOfBirth.drops[0].value = null;
              tools.BVDDateOfBirth.drops[1].value = null;
              tools.BVDDateOfBirth.drops[2].value = null;
              tools.officer = sel_query?.officer ?? "";
              tools.fieldType = {
                text: "Officer",
                value: "officer",
              };
              tools.clearFilters();

              if(sel_query?.dobfrom) {
                let month = sel_query?.dobfrom?.split("-")[1];
                month = Number(month, 10);
                tools.BVDDateOfBirth.drops[0].value = sel_query?.dobfrom?.split("-")[0];
                tools.BVDDateOfBirth.drops[1].value = tools.monthOptions[month-1];
                tools.BVDDateOfBirth.drops[2].value = sel_query?.dobfrom?.split("-")[2];
              }
              // tools.selectedNationalityFilter= null,
              if(sel_query?.countryofresidence?.length){
                let selectedCountry = tools.jurisdictionList?.[0]?.categories?.find((el) => el.label === sel_query.countryofresidence[0]);
                tools.selectedCountryFilter = selectedCountry
              //   let allCountryList = this.$store.getters.getAutoPopulatedCountryList;
              //   let selectedCountry = allCountryList?.find((el) => el.name === sel_query.countryofresidence[0]);
              //   tools.selectedCountryFilter = {
              //     label: selectedCountry?.name,
              //     value: selectedCountry?.code,
              // }
              } else {
                tools.selectedCountryFilter= null;
              }

              if(sel_query?.nationality?.length){
                let allCountryList = this.$store.getters.getAllCountryList;
                let selectedCountry = allCountryList?.find((el) => el.label === sel_query.nationality[0]);
                tools.selectedNationalityFilter = selectedCountry
              //   let allCountryList = this.$store.getters.getAutoPopulatedCountryList;
              //   let selectedCountry = allCountryList?.find((el) => el.name === sel_query.nationality[0]);
              //   tools.selectedNationalityFilter = {
              //     label: selectedCountry?.name,
              //     value: selectedCountry?.code,
              // }
              } else {
                tools.selectedNationalityFilter= null;
              }
            
              tools.officer = sel_query?.officer ?? "";
              tools.selectVal = sel_query?.officer
                ? [sel_query?.officer]
                : [""];
              tools.fieldType = {
                text: "Officer",
                value: "officer",
              };
              let jurisdictionName = null;
              if (sel_query.code) {
                jurisdictionName = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionName ?? null;
              if (sel_query.mode) {
                let search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }

              if (sel_query?.sources) {
                tools.openCorporatesCheck = sel_query.sources.includes(
                  "opencorporates"
                );
                tools.clarifiedByCheck = sel_query.sources.includes(
                  "clarifiedby"
                );
                tools.companyWatchCheck = sel_query.sources.includes(
                  "companywatch"
                );
                tools.bvdCheck = sel_query.sources.includes("bvd");
              }
              break;
            }
            case "address": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.searchKeyword = "";
              tools.officer = "";
              tools.jurisdiction = "";
              tools.address = sel_query?.address ?? "";
              tools.fieldType = {
                text: "Address",
                value: "address",
              };

              break;
            }
            default:
              break;
          }
          tools.showMoreInfo = false;
          tools.selectedQuery = null;
          tools.selectedCard = null;
          tools.triggerRoute = false;
          await tools.search();
          break;
        }
        case "lexis-bridger-tool": {
          await tools.resetFilter();
          tools.lexis_request_id = query_id;
          tools.showResult = false;
          tools.items_count = 10;
          tools.clearFilters();
          // tools.fillYears();
          // tools.fetchAllJuridictions();
          var {
            entity_type,
            person_entity,
            company_entity,
            additional_info,
            ids,
            phones,
            addresses,
            predefinedsearchname
          } = sel_query;

          if (entity_type || additional_info || ids || phones || addresses|| predefinedsearchname) {
            if (entity_type === "Individual") {
              tools.entityType = "person";
              tools.fName = {
                name: person_entity.first_name,
                value: person_entity.first_name,
              };
              tools.mName = {
                name: person_entity.middle_name,
                value: person_entity.middle_name,
              };
              tools.lName = {
                name: person_entity.last_name,
                value: person_entity.last_name,
              };
              tools.fullName = {
                name: person_entity.full_name,
                value: person_entity.full_name,
              };
              // automatically switch to Full Name mode in case full name was used while searching:
              if (!(tools.fName?.value && tools.lName?.value) && tools.fullName?.value)
                tools.fullNameMode = true
              else tools.fullNameMode = false
            } else {
              tools.entityType = "company";
              tools.selectedItem = {
                name: company_entity.name,
                value: company_entity.name,
              };
            }

            if (ids) {
              for (let index in ids) {
                const type = ids[index].type;
                const value = ids[index].number;
                const issuer = ids[index].issuer;
                const issueDate = ids[index].date_issued?.date ? parseInt(ids[index].date_issued.date, 10).toString() : "";
                const issueMonth = ids[index].date_issued?.month ? tools["issueDate"].drops[1].props.options[parseInt(ids[index].date_issued.month, 10) - 1]: "";
                const issueYear = ids[index].date_issued?.year;
                const expirationDate = ids[index].date_expires?.date ? parseInt(ids[index].date_expires.date, 10).toString() : "";
                const expirationMonth = ids[index].date_expires?.month ? tools["expirationDate"].drops[1].props.options[parseInt(ids[index].date_expires.month, 10) - 1]: "";
                const expirationYear = ids[index].date_expires?.year;
                if (type === "National") tools.nationalId.value = value;
                else if (type === "DUNS") tools.dunsId.value = value;
                else if (type === "TaxID") 
                {
                  tools.taxId.value = value;
                  tools.issuingCountry.value ={
                    name:  issuer ||''
                  } 
                  tools["issueDate"].drops[0].value = issueDate;
                  tools["issueDate"].drops[1].value = issueMonth;
                  tools["issueDate"].drops[2].value = issueYear;

                  tools["expirationDate"].drops[0].value = expirationDate;
                  tools["expirationDate"].drops[1].value = expirationMonth;
                  tools["expirationDate"].drops[2].value = expirationYear;
                }
                else tools[type.toLowerCase()].value = value;
              }
            }

            if (phones) {
              for (let index in phones) {
                const type = phones[index].type;
                const value = phones[index].number;
                tools[type.toLowerCase()].value = value;
              }
            }

            if (addresses) {
              for (let index in addresses) {
                const type = addresses[index].type;
                let key;
                if (type === "Current") key = "curr";
                else if (type === "Previous") key = "prev";
                else if (type === "Mailing") key = "mailing";
                tools[`${key}_address1`].value = addresses[index].street1;
                tools[`${key}_address2`].value = addresses[index].street2;
                tools[`${key}_city`].value = addresses[index].city;
                tools[`${key}_state`].value = addresses[index]
                  .stateprovincedistrict
                  ? tools[`${key}_state`].props.options?.[0].categories?.find(
                      (opt) =>
                        opt.value === addresses[index].stateprovincedistrict
                    )
                  : null;
                tools[`${key}_postalCode`].value = addresses[index].postalcode;
                tools[`${key}_lexisCountry`].value = addresses[index].country
                  ? tools[
                      `${key}_lexisCountry`
                    ].props.options?.[0].categories?.find(
                      (opt) => opt.value === addresses[index].country
                    )
                  : null;
              }
            }

            if (additional_info) {
              for (let index in additional_info) {
                const type = additional_info[index].type;
                const value = additional_info[index].number || additional_info[index].value;
                if (type === "Citizenship")
                  tools.citizenship.value = value
                    ? tools.citizenship.props.options?.[0].categories?.find(
                        (opt) => opt.value === value
                      )
                    : null;
                else if (type !== "DOB")
                  tools[type.toLowerCase()].value = value;
                else {
                  tools["lexisDateOfBirth"].drops[0].value = additional_info[
                    index
                  ].date.date
                    ? parseInt(additional_info[index].date.date, 10).toString()
                    : "";
                  tools["lexisDateOfBirth"].drops[1].value = additional_info[
                    index
                  ].date.month
                    ? tools["lexisDateOfBirth"].drops[1].props.options[
                        parseInt(additional_info[index].date.month, 10) - 1
                      ]
                    : "";
                  tools["lexisDateOfBirth"].drops[2].value =
                    additional_info[index].date.year;
                }
              }
            }
            if(predefinedsearchname){
              tools.predefinedsearchname = predefinedsearchname
              
              tools.predefined.value = {
                name: predefinedsearchname
              }
            }
            await tools.mapInputFilters();
            tools.triggerRoute = false;
            // tools.clearFilters();
            await tools.search();
          }
          break;
        }
      }
      
    }catch(e) {
      console.error(e)
    } finally{
      this.setQueryResultLoader(false);
    }
    },
  },
  watch: {
    async "$route.query.query_id"(to, from) {
        //Had to do this because of not getting data from tools-status API.
      setTimeout(async()=>{
        if (to !== from) {
          if (this.$route.query.check_name && this.$route.query.query_id) {
            await this.handleRefreshQueries();
            let query_id = this.$route.query.query_id;
            let selected_query;
            if (this.tabName === "monitoring" || this.tabName === "monitoring-ro")
              selected_query = this.fetchSearchedQueries?.find(
                (el) => el._id === query_id && el.is_monitoring
              );
            else
              selected_query = this.fetchSearchedQueries?.find(
                (el) => el._id === query_id
              );
            const { search_queries } = this.$refs;
            if (selected_query) search_queries.handleClick(selected_query, true);
          } else if (
            this.$route.params.check_name &&
            !this.$route.query.query_id &&
            !this.isSearchQueryClicked
          ) {
            const { search_queries } = this.$refs;
            search_queries.handleClick();
          }
        }
      }, 3000)
    },
  },
};
